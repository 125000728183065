<template>
	<div class="login-container">
		<el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on"
			label-position="left">
			<div class="title-container">
				<h3 class="title">后台管理系统</h3>
			</div>
			

			<el-form-item prop="username">
				<span class="svg-container">
					<img src="../assets/login-1.png" alt="" />
				</span>
				<el-input ref="username" v-model="loginForm.username" placeholder="请输入用户名" name="username" type="text"
					tabindex="1" auto-complete="on" />
			</el-form-item>

			<el-form-item prop="password">
				<span class="svg-container">
					<img src="../assets/login-2.png" alt="" />
				</span>
				<el-input :key="passwordType" ref="password" v-model="loginForm.password" :type="passwordType"
					placeholder="请输入密码" name="password" tabindex="2" auto-complete="on" @keyup.enter.native="handleLogin" />
			</el-form-item>
			<el-form-item prop="random">
				<span class="svg-container">
					<img src="../assets/login-3.png" alt="" />
				</span>
				<el-input style="width: calc(85% - 111px);" ref="random" v-model="loginForm.random" type="text"
					placeholder="请输入验证码" name="random" tabindex="2" auto-complete="on" @keyup.enter.native="handleRandom" />
				<span class="svg-container">
					<img style="width: 111px;height: 48px;" :src="codeImg" @click="getCode()" mode="" />
				</span>
			</el-form-item>
			
			<!-- <div class="choose-addr">
				<div>提示：请选择查询区域，若不选择则显示当前市</div>
				<el-cascader  filterable style="margin: 8px 0px 16px;width: 100%;border: 1px solid #fff;" v-model="chooseCity" :options="addProp" clearable @change="handleChangeM"></el-cascader>
			</div> -->

			<el-button :loading="loading" type="primary" style="width: 100%; margin-bottom: 30px"
				@click.native.prevent="handleLogin">登 录</el-button>
		</el-form>
	</div>
</template>

<script>
	import $ from 'jquery'
	import { provinceAndCityData,CodeToText,TextToCode } from 'element-china-area-data'
	export default {
		name: 'Login',
		data() {
			return {
				addProp: provinceAndCityData,
				chooseCity: [], //uni.$gSyn('chooseCity'),
				loginForm: {
					username: '',
					password: '',
					random: '',
				},
				codeImg: '',
				loginRules: {
					username: [{
						required: true,
						trigger: 'blur',
						message: '请输入用户名！'
					}],
					password: [{
						required: true,
						trigger: 'blur',
						message: '请输入密码！'
					}],
					random: [{
						required: true,
						trigger: 'blur',
						message: '请输入验证码！'
					}],
				},
				timeKey: '',
				loading: false,
				passwordType: 'password',
				redirect: undefined,
			};
		},
		created() {
			this.getCode()
		},
		methods: {
			handleChangeM(e) {
				console.log(e);
				if(e.length>0){
					var value = CodeToText[e[1]]=='市辖区'?CodeToText[e[0]] + ',' +CodeToText[e[0]]:CodeToText[e[0]] + ',' +CodeToText[e[1]];
					localStorage.setItem('chooseCity', value);
					var str = value.split(',')
					this.chooseCity = [
						TextToCode[str[0]].code,
						TextToCode[str[0]][str[1]].code,
					]
					
					localStorage.setItem('chooseCode', e);
				}else{
					localStorage.removeItem('chooseCity');
					localStorage.removeItem('chooseCode');
				}
				
			
			},
			showPwd() {
				if (this.passwordType === 'password') {
					this.passwordType = '';
				} else {
					this.passwordType = 'password';
				}
				this.$nextTick(() => {
					this.$refs.password.focus();
				});
			},
			dateMethod() {
				let date = new Date();
				let year = date.getFullYear(); //年
				let month = date.getMonth() + 1; //月
				month = month < 10 ? '0' + month : month;
				let day = date.getDate(); //天
				day = day < 10 ? '0' + day : day
				let hours = date.getHours(); //小时
				hours = hours < 10 ? '0' + hours : hours;
				let Minutes = date.getMinutes(); //分
				Minutes = Minutes < 10 ? '0' + Minutes : Minutes;
				let Seconds = date.getSeconds(); //秒
				Seconds = Seconds < 10 ? '0' + Seconds : Seconds;
				let timeS = date.getTime();
				let time1 = '';
				let dateList = '';
				let timeList = '';
				let monthData = ''
				// if (t == '%') {
				// 	time1 = year + '年' + month + '月' + day + '日 ' + hours + '时' + Minutes + '分' + Seconds + '秒';
				// 	dateList = year + '年' + month + '月' + day + '日';
				// 	monthData = year + '年' + month + '月';
				// 	timeList = hours + '时' + Minutes + '分' + Seconds + '秒';
				// } else {
				// 	time1 = year + '-' + month + '-' + day + ' ' + hours + ':' + Minutes + ':' + Seconds;
				// 	dateList = year + '-' + month + '-' + day;
				// 	timeList = hours + ':' + Minutes + ':' + Seconds;
				// }
				return {
					time: timeS,
					d: time1,
					year: year,
					month: month,
					day: day,
					hours: hours,
					Minutes: Minutes,
					Seconds: Seconds,
					dateList: dateList,
					timeList: timeList,
					monthData: monthData
				}
			},
			getCode() {
				this.timeKey = this.dateMethod().time;
				console.log(this.timeKey);
				$.get({
					//这里的url不能写到data对象的外面
					url:'/six-emotions-admin/sys/randomImage/' + this.timeKey,
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					success: (data) => {
						this.codeImg = data.result;
						// console.log(this.timeKey, '验证码');
					}
				})

			},
			// 此处进行登陆操作
			handleLogin() {
				//  开始效验 并登陆
				let string = this.timeKey.toString();
				var value = {
					captcha: this.loginForm.random, //验证码
					checkKey: string, //验证码key
					password: this.loginForm.password, //密码	
					username: this.loginForm.username, //账号
				}
				if(this.loginForm.username!='admin'){
					alert('只有超级管理员才能登录')
					return
				}
				$.ajax({
					url:'/six-emotions-admin/sys/login',
					data: JSON.stringify(value), //方法二   测试ok
					method: "post",
					dataType: "json",
					contentType: 'application/json',
					success: data=>{
						if (data.code == 200) {
							this.codeImg = data.result;
							localStorage.setItem('token', data.result.token);
							this.$router.push('/')
							console.log(data);
						}else{
							this.getCode();
							alert(data.message)
						}
						
					}
				});
			},
		},
	};
</script>

<style lang="scss">
	$bg: #283443;
	$light_gray: #fff;
	$cursor: #fff;
	
	.choose-addr {
		// width: 60%;
		font-size: 12px;
		text-align: center;
		padding-top: 10px;
		padding-bottom: 20px;
		color: #fff;
	}

	.login-container {
		.el-input {
			display: inline-block;
			height: 47px;
			width: 85%;

			input {
				background: transparent;
				border: 0px;
				-webkit-appearance: none;
				border-radius: 0px;
				padding: 12px 5px 12px 15px;
				color: $light_gray;
				height: 47px;
				caret-color: $cursor;

				&:-webkit-autofill {
					box-shadow: 0 0 0px 1000px $bg inset !important;
					-webkit-text-fill-color: $cursor !important;
				}
			}
		}

		.el-form-item {
			border: 1px solid rgba(255, 255, 255, 0.1);
			background: rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			color: #454545;
		}
	}
</style>

<style lang="scss" scoped>
	$bg: #2d3a4b;
	$dark_gray: #889aa4;
	$light_gray: #eee;

	.login-container {
		min-height: 100%;
		width: 100%;
		background-color: $bg;
		overflow: hidden;

		.login-form {
			position: relative;
			width: 520px;
			max-width: 100%;
			padding: 160px 35px 0;
			margin: 0 auto;
			overflow: hidden;
		}

		.tips {
			font-size: 14px;
			color: #fff;
			margin-bottom: 10px;

			span {
				&:first-of-type {
					margin-right: 16px;
				}
			}
		}

		.svg-container {
			padding: 6px 5px 6px 15px;
			display: inline-block;

			img {
				width: 1.1rem;
				vertical-align: middle;
			}
		}

		.title-container {
			position: relative;

			.title {
				font-size: 20px;
				color: $light_gray;
				margin: 0px auto 40px auto;
				text-align: center;
				font-weight: bold;
			}
		}

		.show-pwd {
			position: absolute;
			right: 10px;
			top: 7px;
			font-size: 16px;
			cursor: pointer;
			user-select: none;

			img {
				width: 1.1rem;
				height: 1.1rem;
				vertical-align: middle;
			}
		}
	}
</style>